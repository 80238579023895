import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import BlogSinglePost from "../components/Blog/BlogSinglePost";

const Post = ({ data }) => {
   return (
      <Layout headerStyle="standard" headerLinkColor="dark" headerHasBorder={false}>
         <SearchEngineOptimization
            title="Automotive Fiberglass and the Improved Fiberglass Market"
            description="The fiberglass market has risen over the past few years. Learn how the automotive industry has become one of the largest contributors to the fiberglass market."
            // openGraphImage={data.openGraphImage.publicURL}
            // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <BlogSinglePost
            title="Automotive Fiberglass and the Improved Fiberglass Market"
            date="May 15, 2022"
            author="Painted Rhino"
            category=""
            featuredImage={data.featuredImage.childImageSharp.fluid}
            content={
               <>
                  <p>
                     The <a href="https://www.paintedrhino.com/fiberglass-composite-parts-manufacturing/">fiberglass</a> market has risen tremendously
                     over the past few years, and one industry is driving a significant portion - the automotive industry. As it turns out, automotive
                     fiberglass is one of the largest contributors to the fiberglass market.
                  </p>

                  <p>
                     Read on to learn more about <a href="https://www.paintedrhino.com/fiberglass-types/">fiberglass</a> in cars. We'll discuss how
                     this market has shifted upwards over the years. Now more than ever, fiberglass has become a critical part of vehicle
                     fabrication. 
                  </p>

                  <h2>How Much Growth is Projected?</h2>
                  <p>
                     There is a projection for growth in the fiberglass market. From 2020 to 2025, the industry expects to grow from a worth of 11.5
                     billion to 14.3 billion. Fiberglass use in the automotive and construction industries is the driving force.
                  </p>

                  <h2>Why is Fiberglass Utilized in the Automotive Industry?</h2>
                  <p>
                     Why is fiberglass utilized in the automotive industry? It's a product with an excellent weight-to-strength ratio, providing a way
                     to create critical components without taking away from the vehicle's aerodynamic ability. It also reduces greenhouse gas
                     emissions, an item that regulation covers.
                  </p>

                  <p>
                     E-glass is projected to be the most popular market, ideal for its cost-efficiency and corrosion resistance. Thermoplastic resins
                     come close behind. Composite segments will rule from 2020 to 2025.
                  </p>

                  <p>
                     Fiberglass is an excellent material that has begun to replace heavier materials such as steel and aluminum. It has contributed to
                     growth in multiple countries known for automobile production. The Asia Pacific is predicted to have the fastest growth when
                     considering fiberglass in the automotive industry.
                  </p>

                  <p>
                     Automotive fiberglass is now a staple in the car world. It will continue to rise as car standards change and demand increases for
                     more eco-friendly materials. 
                  </p>

                  <h2>What Other Items Utilize Fiberglass?</h2>
                  <p>
                     <a href="https://www.paintedrhino.com/benefits-custom-made-fiberglass-parts/">Fiberglass</a> in cars is one of the driving
                     forces, but there are others. What are other things made from fiberglass? Other items push the fiberglass market forward along
                     with fiberglass in cars.
                  </p>

                  <p>Here are a few items made from fiberglass:</p>

                  <ul>
                     <li> Boat hulls</li>
                     <li> Bottling liner for bottles</li>
                     <li> Docks and marina</li>
                     <li> Cooling towers</li>
                     <li> Roofing on houses</li>
                     <li> Bathtubs in bathrooms</li>
                     <li> Aircrafts</li>
                  </ul>

                  <p>
                     There are many benefits to fiberglass. It's lightweight, durable, and corrosion-resistant in a way few materials can match on the
                     market today. In addition, it's slip-resistant and works well in most environments.
                  </p>

                  <h2>Conclusion</h2>
                  <p>
                     Fiberglass in cars is common and one of the growth factors for the fiberglass market. The material is lightweight, strong, and
                     less harmful to the environment. It's also corrosion-resistant and readily available, making it an ideal automotive material.
                  </p>

                  <p>
                     <a data-modal-open="modal-contact">Contact us today</a> for more information regarding your fiberglass needs. We are ready to
                     help you take your project to the next level.
                  </p>
               </>
            }
         />
      </Layout>
   );
};

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/FB_Global.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/Twitter_Global.jpg" }) {
         publicURL
      }
      featuredImage: file(relativePath: { eq: "blog/Painted-Rhino-Automotive-Fiberglass-and-the-Improved-Fiberglass-Market.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 2500) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
   }
`;

export default Post;
